import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { ucFirst } from '../../utils/caseconverters';

import styles from './Icon.module.scss';

const ArrowThin = dynamic(() => import('./icons/arrow-thin'));
const Arrow = dynamic(() => import('./icons/arrow'));
const BreadcrumbChevron = dynamic(() =>
    import('./icons/breadcrumb-chevron')
);
const Check = dynamic(() => import('./icons/check'));
const ChevronThin = dynamic(() => import('./icons/chevron-thin'));
const ChevronThinLarge = dynamic(() => import('./icons/chevron-thin-large'));
const Chevron = dynamic(() => import('./icons/chevron'));
const Cross = dynamic(() => import('./icons/cross'));
const CrossSmall = dynamic(() => import('./icons/cross-small'));
const Download = dynamic(() => import('./icons/download'));
const DownloadSmall = dynamic(() => import('./icons/download-small'));
const External = dynamic(() => import('./icons/external'));
const Filter = dynamic(() => import('./icons/filter'));
const Hamburger = dynamic(() => import('./icons/hamburger'));
const Info = dynamic(() => import('./icons/info'));
const Link = dynamic(() => import('./icons/link'));
const Minus = dynamic(() => import('./icons/minus'));
const NordRedBoxLarge = dynamic(() => import('./icons/nord-red-box-2'));
const NordRedBoxMedium = dynamic(() => import('./icons/nord-red-box-3'));
const NordRedBoxSmall = dynamic(() => import('./icons/nord-red-box-4'));
const Pause = dynamic(() => import('./icons/pause'));
const Pin = dynamic(() => import('./icons/pin'));
const Play = dynamic(() => import('./icons/play'));
const Plus = dynamic(() => import('./icons/plus'));
const Search = dynamic(() => import('./icons/search'));
const SearchSmall = dynamic(() => import('./icons/search-small'));
const Share = dynamic(() => import('./icons/share'));
const Facebook = dynamic(() => import('./icons/facebook'));
const Instagram = dynamic(() => import('./icons/instagram'));
const Twitter = dynamic(() => import('./icons/twitter'));
const TwitterX = dynamic(() => import('./icons/twitterx'));
const Youtube = dynamic(() => import('./icons/youtube'));
const Linkedin = dynamic(() => import('./icons/linked-in'));
const MagnifyingGlass = dynamic(() => import('./icons/magnifying-glas'));
const MagnifyingGlassWhite = dynamic(() =>
    import('./icons/magnifying-glas-white')
);
const CheckGreen = dynamic(() => import('./icons/check-green'));
const CrossRed = dynamic(() => import('./icons/cross-red'));

export const allIcons = {
    breadcrumbChevron: BreadcrumbChevron,
    arrowThin: ArrowThin,
    arrow: Arrow,
    check: Check,
    chevronThin: ChevronThin,
    chevronThinLarge: ChevronThinLarge,
    chevron: Chevron,
    cross: Cross,
    crossSmall: CrossSmall,
    download: Download,
    downloadSmall: DownloadSmall,
    external: External,
    filter: Filter,
    hamburger: Hamburger,
    info: Info,
    link: Link,
    minus: Minus,
    nordRedBoxLarge: NordRedBoxLarge,
    nordRedBoxMedium: NordRedBoxMedium,
    nordRedBoxSmall: NordRedBoxSmall,
    pause: Pause,
    pin: Pin,
    play: Play,
    plus: Plus,
    search: Search,
    searchSmall: SearchSmall,
    share: Share,
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter,
    twitterx: TwitterX,
    youtube: Youtube,
    linkedin: Linkedin,
    magnifyingGlass: MagnifyingGlass,
    magnifyingGlassWhite: MagnifyingGlassWhite,
    checkGreen: CheckGreen,
    crossRed: CrossRed,
};

const Icon = ({ type, size, modifiers, dimensions, srText }) => {
    const Svg = allIcons[type];

    return (
        <span
            className={classNames(
                styles['Icon'],
                styles['Icon--' + ucFirst(type)],
                styles['Icon--' + ucFirst(size)],
                modifiers.map(
                    (modifier) => styles['Icon--' + ucFirst(modifier)]
                )
            )}
            style={
                dimensions
                    ? {
                        width: dimensions.width,
                        height: dimensions.height,
                    }
                    : ''
            }
        >
            {!!Svg && <Svg />}
        </span>
    );
};

Icon.propTypes = {
    type: PropTypes.oneOf(Object.keys(allIcons)),
    size: PropTypes.oneOf([
        '',
        'xsmall',
        'small',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
        'xxxlarge',
        'xxxxlarge',
    ]),
    modifiers: PropTypes.array,
    dimensions: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string,
    }),
    srText: PropTypes.string,
};

Icon.defaultProps = {
    size: '',
    type: '',
    modifiers: [],
    dimensions: {},
    srText: '',
};

export default Icon;
